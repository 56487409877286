<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" @click="openModal">ثبت رنگ</b-button>
    <b-card title="رنگ‌ها" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">اسم</label>
                  <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                </div>
              </div>

              <div class="col-md-6">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="1">فعال</b-form-select-option>
                    <b-form-select-option value="0">غیرفعال</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

        <template v-slot:cell(created_at)="data">
          {{data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(value)="data">
          <span v-if="data.item.multi == '0'" class="badge"
            :style="'background:' + data.item.code">{{ data.item.code }}</span>
          <a v-else-if="data.item.multi == '1'" :href="data.item.image" target="_blank">
            <b-img :src="data.item.image" style="max-width: 60px; max-height: 50px;" rounded></b-img>
          </a>
        </template>
        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>

        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>
    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="new-modal" title="ثبت رنگ" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <div class="row">
            <div :class="selected_multi == '0'? 'col-md-6' : 'col-md-12'">
              <b-form-group>
                <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-input id="name" name="name" data-required :disabled="disabled"></b-form-input>
              </b-form-group>
            </div>
            <div v-if="selected_multi == '0'" class="col-md-6">
              <b-form-group>
                <label for="code"> رنگ <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i> </label>
                <div>
                  <span class="btn btn-warning" @click="$root.$emit('bv::show::modal', 'color-item')"><i
                      class="fas fa-palette"></i></span>
                  <span class="badge mr-1" :style="'background:' + colors.hex">{{ colors.hex }}</span>
                </div>
                <input type="hidden" name="code" :value="colors.hex" :disabled="disabled">
              </b-form-group>
            </div>
          </div>
          <b-form-group v-if="selected_multi == '1'">
            <label for="image"> عکس <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-file id="image" name="image" data-required :disabled="disabled"></b-form-file>
          </b-form-group>
          <b-row>
            <b-col>
              <div class="form-group">
                <label class="custom-switch">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
                    :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label class="custom-switch">
                  <input type="checkbox" name="multi" class="custom-switch-input" v-model="selected_multi"
                    :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">چند رنگ</span>
                </label>
              </div>
            </b-col>
          </b-row>
          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش رنگ" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <div class="row">
            <div :class="edit.multi == '1' || edit.multi ? 'col-md-12' : 'col-md-6'">
              <b-form-group>
                <label for="edit-name"> اسم <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="edit-name" name="name" v-model="edit.name" data-required :disabled="disabled">
                </b-form-input>
              </b-form-group>
            </div>
            <div v-if="edit.multi == '0' || !edit.multi" class="col-md-6">
              <b-form-group>
                <label for="code"> رنگ <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <div>
                  <span class="btn btn-warning" @click="$root.$emit('bv::show::modal', 'color-item')"><i
                      class="fas fa-palette"></i></span>
                  <span v-if="edit.code.hex" class="badge mr-1"
                    :style="'background:' + edit.code.hex ">{{ edit.code.hex }}</span>
                  <span v-else class="badge mr-1"
                    :style="'background:' + edit.code">{{ edit.code != null ? edit.code : '#000000' }}</span>
                </div>
                <input v-if="edit.code.hex" type="hidden" name="code" :value="edit.code.hex" :disabled="disabled">
                <input v-else type="hidden" name="code" :value="edit.code" :disabled="disabled">
              </b-form-group>
            </div>
          </div>
          <b-form-group v-if="edit.multi == '1' || edit.multi " label="تصویر" label-for="edit-image">
            <b-form-file id="edit-image" name="image" :disabled="disabled"></b-form-file>
          </b-form-group>
          <b-row>
            <b-col>
              <div class="form-group">
                <label class="custom-switch">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                    :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label class="custom-switch">
                  <input type="checkbox" name="multi" class="custom-switch-input" v-model="edit.multi"
                    :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">چند رنگ</span>
                </label>
              </div>
            </b-col>
          </b-row>

          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="PUT" name="_method">
        </form>
      </div>
    </b-modal>

    <b-modal id="color-item" hide-footer title="انتخاب رنگ" size="sm">
      <sketch-picker v-if="edit.code" class="mr-5" v-model="edit.code" />
      <sketch-picker v-else class="mr-5" v-model="colors" />
    </b-modal>
  </div>
</template>
<script>
  import mixins from './mixins/mixins'
  import sketch from 'vue-color/src/components/Sketch.vue'
  export default {
    components: {
      'sketch-picker': sketch
    },
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/color',
        title: 'رنگ ها',
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'name',
            label: 'اسم'
          },
          {
            key: 'value',
            label: 'مقدار'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          // { key: 'longitude', label: 'عرض جغرافیایی' },
          // { key: 'latitude', label: 'طول جغرافیایی' },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ],
        selected_status: '1',
        selected_filter_status: '',
        selected_multi: 0,
        colors: {
          hex: '#000000'
        }
      }
    },
    methods: {
      openModal() {
        this.colors.hex = '#000000'
        this.edit = {}
        this.$root.$emit('bv::show::modal', 'new-modal')
      },
      editItem(index) {
        this.edit = {}
        let item = window.clone(this.items.data.data[index])
        item.index = index
        this.edit = item
        this.edit.code = this.edit.code == null ? this.edit.code = {
          hex: '#000000'
        } : this.edit.code
        this.$root.$emit('bv::show::modal', 'edit-modal')
      },
    },
    created() {
      this.loadItems()
      this.searchParams = {
        name: '',
        status: '',
      }
    }
  }
</script>